import defaultPhoto from 'assets/images/cars/default.png';
import alfa_romeo from 'assets/images/cars/alfa romeo.png';
import audi from 'assets/images/cars/audi.png';
import bmw from 'assets/images/cars/bmw.png';
import chevrolet from 'assets/images/cars/chevrolet.png';
import citroen from 'assets/images/cars/citroen.png';
import cupra from 'assets/images/cars/cupra.png';
import ds from 'assets/images/cars/ds.png';
import dacia from 'assets/images/cars/dacia.png';
import excalibur from 'assets/images/cars/excalibur.png';
import fiat from 'assets/images/cars/fiat.png';
import ford from 'assets/images/cars/ford.png';
import hyundai from 'assets/images/cars/hyundai.png';
import iveco from 'assets/images/cars/iveco.png';
import jaguar from 'assets/images/cars/jaguar.png';
import jeep from 'assets/images/cars/jeep.png';
import kia from 'assets/images/cars/kia.png';
import lynk_co from 'assets/images/cars/link & co.png';
import landrover from 'assets/images/cars/landrover.png';
import lexus from 'assets/images/cars/lexus.png';
import mazda from 'assets/images/cars/mazda.png';
import mercedes from 'assets/images/cars/mercedes.png';
import mini from 'assets/images/cars/mini.png';
import mg from 'assets/images/cars/mg.png';
import mitsubishi from 'assets/images/cars/mitsubishi.png';
import nissan from 'assets/images/cars/nissan.png';
import opel from 'assets/images/cars/opel.png';
import peugeot from 'assets/images/cars/peugeot.png';
import renault from 'assets/images/cars/renault.png';
import seat from 'assets/images/cars/seat.png';
import skoda from 'assets/images/cars/skoda.png';
import suzuki from 'assets/images/cars/suzuki.png';
import toyota from 'assets/images/cars/toyota.png';
import volkswagen from 'assets/images/cars/volkswagen.png';
import volvo from 'assets/images/cars/volvo.png';

const CAR_PHOTOS = {
  'alfa romeo': alfa_romeo,
  audi,
  bmw,
  chevrolet,
  citroen,
  cupra,
  ds,
  dacia,
  excalibur,
  fiat,
  ford,
  hyundai,
  iveco,
  jaguar,
  jeep,
  kia,
  'lynk & co': lynk_co,
  landrover,
  lexus,
  mazda,
  mercedes,
  mini,
  mg,
  mitsubishi,
  nissan,
  opel,
  peugeot,
  renault,
  seat,
  skoda,
  suzuki,
  volkswagen,
  volvo,
  toyota
};

const getCarPhoto = (brand) => {
  return CAR_PHOTOS[brand.toLowerCase()] || defaultPhoto;
};

export default getCarPhoto;
